import React from 'react'

export default function Home() {

    const MHCGlobal = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/MHC%20Global/Grid/MH%20Cockpit.jpg",
            href: "https://mhcockpit.com/",
            target: "blank"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHC%20Global/Grid/MH%20Intellect.jpg",
            href: "https://mhintellect.com/",
            target: "blank"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHC%20Global/Grid/MH%20Agro.jpg",
            href: "https://mhagro.mhglobal.info/",
            target: "blank"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHC%20Global/Grid/MH%20Machines.jpg",
            href: "/"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHC%20Global/Grid/MH%20Healthcare.jpg",
            href: "/"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/MHC%20Global/Grid/MH%20Innovation.jpg",
            href: "/"
        },

    ]

    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/MHC%20Global/Cover.jpg' alt='MHC Global' />
            </div>
            <div className='px-80 xl:px-80 sm:px-40 max-sm:px-10 py-20 max-sm:py-10 text-justify font-semibold'>
                <p>
                    At MHC Global, we're dedicated to making a positive impact through technology. MH Intellect helps school kids thrive with innovative educational tools. MH Agro supports sustainable farming, giving agriculture a tech boost. MH Cockpit partners with colleges to offer aviation degrees and pilot training. MH Innovation collaborates with experts to drive forward-thinking initiatives. We're here to improve lives and help communities grow.
                </p>
            </div>
            <div className='flex max-sm:block justify-center items-center px-40 max-sm:px-10'>
                <div className='w-full'>
                    <video autoPlay muted loop className="w-full h-full object-cover">
                        <source src="https://mhc-global.blr1.cdn.digitaloceanspaces.com/slider4.mp4.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='w-1/2 max-sm:w-full'>
                    <p className='font-bold text-5xl'><span className='text-[#fa1c1c]'>Future</span> Is Here</p>
                </div>
            </div>
            <div className='py-20 max-sm:py-10'>
                <div>
                    <p className='text-center font-bold text-3xl'><span className='bg-black bg-opacity-30 px-5 py-1 rounded-lg'>We are accredited by</span></p>
                </div>
                <div className='flex justify-center items-center space-x-8 pt-8'>
                    <div className='shadow-xl h-32 flex justify-center items-center px-3 rounded-xl'>
                        <img src='https://ik.imagekit.io/mhcockpit1/MHC%20Global/Startup%20India.jpg' alt='Accredited' />
                    </div>
                    <div className='shadow-xl h-32 flex justify-center items-center px-3 rounded-xl'>
                        <img src='https://ik.imagekit.io/mhcockpit1/MHC%20Global/MSME.jpg' alt='Accredited' />
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center px-1 py-10'>
                <div className='grid grid-cols-2 gap-1'>
                    {MHCGlobal.map((image, index) => (
                        <div key={index}>
                            <a href={image.href} target={image.target}>
                                <img src={image.src} alt="MHC Global" />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
